import { Button, Card, Form, InputNumber, Select, Space, Tooltip } from "antd";
import { useMsal } from "@azure/msal-react";
import "../admin-page.css";
import { GetAccessToken } from "../../../utils/auth-utils";
import { scopes } from "../../../authConfig";
import {
  ArrowRightOutlined,
  CloseOutlined,
  DownOutlined,
  SyncOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import { SalesChannel } from "../../../dto/model";
import { BookingConfiguration, Configuration, ConfigurationPostBody, MaxCountConfiguration, SnoozeConfiguration } from "../../../dto/admin-config-models";
import { activateConfig, getConfigurations, postConfiguration, updateConfig } from "../../../services/admin-config-service";

const AdminConfiguration = () => {
  const { instance, inProgress, accounts } = useMsal();
  const [snoozeForm] = Form.useForm();
  const [counterForm] = Form.useForm();
  const [configurations, setConfigurations] = useState<Configuration[] | null>(
    null
  );
  const [selectedConfiguration, setSelectedConfiguration] = useState<Configuration | null>(null);
  const [editingConfig, setEditingConfig] = useState<boolean>(false);
  const [formDisabled, setFormDisabled] = useState<boolean>(true);
  const [configKey, setConfigKey] = useState<number>(-1);
  const [configValue, setConfigValue] = useState<any>("");
  const [disabled, setDisabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [saveAsLoading, setSaveAsLoading] = useState<boolean>(false);
  const [activateLoading, setActivateLoading] = useState<boolean>(false);
  const [salesChannel, setSalesChannel] = useState<SalesChannel>(SalesChannel.DigitalSales);

  useEffect(() => {
    const fetchConfigurations = async () => {
      try {
        const token = await GetAccessToken(
          instance,
          inProgress,
          scopes.salesAppointmentApi
        );
        const data = await getConfigurations(token.accessToken, salesChannel);
        setConfigurations(data!);
      } catch (error) {
        alert('Error fetching configurations:');
      }
    };
    fetchConfigurations();

    setEditingConfig(false);
    setFormDisabled(true);
    setLoading(false);
    setConfigValue("Select Configuration from the dropdown");
    setConfigKey(-1);
  }, []);

  useEffect(() => {
    loadConfigurationIntoForm();
  }, [selectedConfiguration]);


  const isFormDataValid = async () => {
    let error = true;
    await counterForm.validateFields().then(async (counterValues) => {
      await snoozeForm.validateFields().then((snoozeValues) => {
        const maxCounter = counterValues.items[0].firstTimeScheduling;
        snoozeValues.items[0].snooze.map(
          (range: any, i: number, arr: any[]) => {
            if (range.start >= range.end) {
              alert(
                `Invalid snooze configuration at line ${i + 1
                }: start must be less than end.`
              );
              error = false;
              return;
            }
            if (i < arr.length - 1 && range.end >= arr[i + 1].start) {
              alert(
                `Invalid snooze configuration at line ${i + 1
                }: end must be smaller than the start of the next configuration.`
              );
              error = false;
              return;
            }
            if (i < arr.length - 1 && arr[i + 1].start - range.end > 1) {
              alert(
                `Invalid snooze configuration at line ${i + 1
                }: there must be no gap between snooze end and start.`
              );
              error = false;
              return;
            }
            if (range.start >= maxCounter || range.end >= maxCounter) {
              alert(
                `Invalid snooze configuration at line ${i + 1
                }: start and end must be smaller than maxCounter.`
              );
              error = false;
              return;
            }
          }
        );
      });
    });
    return error;
  };

  const loadConfigurationIntoForm = () => {
    if (!selectedConfiguration) {
      return;
    }
    const maxCountConfiguration =
      selectedConfiguration.bookingConfiguration.maxCountConfiguration;
    const snoozeConfiguration =
      selectedConfiguration.bookingConfiguration.snoozeConfiguration;

    counterForm.setFieldsValue({
      items: [
        {
          reactivatedCustomer: maxCountConfiguration.reactivatedCustomer,
          firstTimeScheduling: maxCountConfiguration.firstTimeScheduling,
          reschedulingNeeded: maxCountConfiguration.reschedulingNeeded,
        },
      ],
    });

    snoozeForm.setFieldsValue({
      items: [
        {
          snooze: snoozeConfiguration.map((el) => {
            return {
              start: el.start,
              end: el.end,
              duration: el.duration,
              timeFrame: el.timeFrame,
            };
          }),
        },
      ],
    });
  };

  const convertFormToBookingConfiguration = () => {
    const counterItems = counterForm.getFieldsValue().items[0];
    const snoozeItems = snoozeForm.getFieldsValue().items[0].snooze;

    const maxCountConfiguration: MaxCountConfiguration = {
      reactivatedCustomer: counterItems.reactivatedCustomer,
      firstTimeScheduling: counterItems.firstTimeScheduling,
      reschedulingNeeded: counterItems.reschedulingNeeded,
    };
    const configuration: SnoozeConfiguration[] = snoozeItems.map(
      (el: {
        start: number;
        end: number;
        duration: number;
        timeFrame: string;
      }) => {
        const range: SnoozeConfiguration = {
          start: el.start,
          end: el.end,
          duration: el.duration,
          timeFrame: el.timeFrame,
        };
        return range;
      }
    );

    const bookingConfiguration: BookingConfiguration = {
      snoozeConfiguration: configuration,
      maxCountConfiguration,
    };

    return bookingConfiguration;
  };

  const collectAndSaveConfig = async () => {
    if (!(await isFormDataValid())) return;
    const comment = prompt("Enter name of the configuration");
    if (!comment) {
      alert("Name is required");
      return;
    }

    const transformedConfig = convertFormToBookingConfiguration();

    const postBody: ConfigurationPostBody = {
      azureId: accounts[0].localAccountId,
      comment: comment!,
      maxCountConfiguration: transformedConfig.maxCountConfiguration,
      configuration: transformedConfig.snoozeConfiguration,
    };
    const token = await GetAccessToken(
      instance,
      inProgress,
      scopes.salesAppointmentApi
    );
    const postResult = await postConfiguration(token.accessToken, postBody, salesChannel);
    if (postResult) {
      alert("New configuration saved");
      setEditingConfig(false);
      setFormDisabled(true);
    } else {
      alert("Failed to save configuration");
    }
  };

  const activateSelectedConfiguration = async () => {
    if (!selectedConfiguration) {
      alert("No configuration selected");
      return;
    }
    const token = await GetAccessToken(
      instance,
      inProgress,
      scopes.salesAppointmentApi
    );
    const activateResult = await activateConfig(token.accessToken, selectedConfiguration.id, salesChannel);
    activateResult
      ? alert("Configuration activated")
      : alert("Failed to activate configuration");
  }

  const updateSelectedConfiguration = async () => {
    if (!(await isFormDataValid())) return;

    if (!selectedConfiguration) {
      alert("No configuration selected");
      return;
    }

    const comment = prompt("Enter name of the configuration");
    if (!comment) {
      alert("Name is required");
      return;
    }
    const config = convertFormToBookingConfiguration();
    selectedConfiguration.bookingConfiguration = config;
    selectedConfiguration.comment = comment;

    const token = await GetAccessToken(
      instance,
      inProgress,
      scopes.salesAppointmentApi
    );
    const updateResult = await updateConfig(token.accessToken, selectedConfiguration, salesChannel);

    if (updateResult) {
      alert("Configuration updated");
      setEditingConfig(false);
      setFormDisabled(true);
    } else {
      alert("Failed to update configuration");
    }
  };

  const saveClicked = async () => {
    setDisabled(true);
    setSaveLoading(true);
    await updateSelectedConfiguration();
    await refreshConfig();
    setDisabled(false);
    setSaveLoading(false);
  };

  const saveAsClicked = async () => {
    setSaveAsLoading(true);
    setDisabled(true);
    await collectAndSaveConfig();
    await refreshConfig();
    setSaveAsLoading(false);
    setDisabled(false);
  };

  const editClicked = async () => {
    setFormDisabled(false);
    setEditingConfig(true);
  };

  const discardClicked = async () => {
    setEditingConfig(false);
    setFormDisabled(true);
    loadConfigurationIntoForm();
  };

  const refreshConfig = async () => {
    const token = await GetAccessToken(
      instance,
      inProgress,
      scopes.salesAppointmentApi
    );
    const newConfig = await getConfigurations(token.accessToken, salesChannel);
    setConfigurations(newConfig);
    if (configKey !== -1) {
      setConfigValue(() => {
        setSelectedConfiguration(newConfig![configKey]);
        const config = newConfig![configKey];
        return {
          value: configKey,
          label: (
            <span>
              {config.active ? (
                <span style={{ color: "green" }}>(Active)</span>
              ) : (
                <span style={{ color: "red" }}>(Inactive)</span>
              )}{" "}
              {config.comment}
              {config.default ? (
                <span style={{ color: "gray", float: "right" }}>Default</span>
              ) : (
                <span style={{ color: "gray", float: "right" }}></span>
              )}
            </span>
          ),
        };
      });
    }
  };

  const salesChannelOptions = Object.keys(SalesChannel).map(key => ({
    label: `${SalesChannel[key as keyof typeof SalesChannel]}`,
    value: SalesChannel[key as keyof typeof SalesChannel]
  }));

  return (
    <>
      <div className="admin-container-borderless flex-admin-vertical-container">
        <div>
          <Tooltip title="Displays current configuration profile">
            <Select
              disabled={true}
              style={{ width: 200, marginRight: 10 }}
              value={salesChannel}
              onChange={(value: SalesChannel) => setSalesChannel(value)}
              options={salesChannelOptions}
            />
          </Tooltip>
          {configurations && (
            <Select
              style={{ width: 400, marginRight: 10 }}
              value={configValue}
              defaultValue={
                "Select the available configuration from the dropdown."
              }
              disabled={!formDisabled}
              options={configurations.map((config, index) => ({
                value: index,
                label: (
                  <span>
                    {config.active ? (
                      <span style={{ color: "green" }}>(Active)</span>
                    ) : (
                      <span style={{ color: "red" }}>(Inactive)</span>
                    )}{" "}
                    {config.comment}
                    {config.default ? (
                      <span style={{ color: "gray", float: "right" }}>
                        Default
                      </span>
                    ) : (
                      <span style={{ color: "gray", float: "right" }}></span>
                    )}
                  </span>
                ),
              }))}
              onSelect={(value, label) => {
                setConfigKey(+value);
                setConfigValue(label);
                setSelectedConfiguration(configurations[+value]);
              }}
            ></Select>
          )}
          <Tooltip title="Refresh configurations">
            <SyncOutlined
              onClick={async () => {
                setDisabled(true);
                setLoading(true);
                await refreshConfig();
                setLoading(false);
                setDisabled(false);
              }}
              spin={loading}
            ></SyncOutlined>
          </Tooltip>
          {selectedConfiguration && (
            <Tooltip title="Open editing mode">
              <Button
                type="primary"
                disabled={editingConfig}
                onClick={editClicked}
                style={{ float: "right" }}
              >
                Edit
              </Button>
            </Tooltip>
          )}
          {selectedConfiguration && !selectedConfiguration.active && (
            <Tooltip title="Activate selected configuration">
              <Button
                type="default"
                onClick={async () => {
                  setActivateLoading(true);
                  setDisabled(true);
                  await activateSelectedConfiguration();
                  await refreshConfig();
                  setActivateLoading(false);
                  setDisabled(false);
                }}
                loading={activateLoading}
                disabled={editingConfig || disabled}
                style={{ float: "right", marginRight: 10 }}
              >
                Activate
              </Button>
            </Tooltip>
          )}
        </div>
        <div className="flex-admin-vertical-container">
          <div className="flex-admin-container">
            <Form
              disabled={formDisabled}
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 10 }}
              form={counterForm}
              name="static_counter_form"
              style={{ width: 600 }}
              autoComplete="off"
              initialValues={{ items: [{}] }}
            >
              <Form.List name="items">
                {(fields, { add, remove }) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {fields.map((field) => (
                      <Card
                        className="admin-border"
                        bodyStyle={{ float: "left" }}
                        size="small"
                        key={field.key}
                      >
                        <h4 style={{ margin: "10px 10px 0px 0" }}>
                          Maximum Call Counters
                        </h4>
                        <div style={{ margin: "10px 0 12px 0" }}>
                          Please specify how much times a customer should be
                          called before they are removed from the queue.
                        </div>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "required",
                            },
                          ]}
                          style={{ marginBottom: 10 }}
                          labelAlign="left"
                          label="Reactivated Customer"
                          name={[field.name, "reactivatedCustomer"]}
                        >
                          <InputNumber
                            inputMode="numeric"
                            required={true}
                            defaultValue={0}
                            min={0}
                          />
                        </Form.Item>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "required",
                            },
                          ]}
                          style={{ marginBottom: 10 }}
                          labelAlign="left"
                          label="First Time Scheduling"
                          name={[field.name, "firstTimeScheduling"]}
                        >
                          <InputNumber
                            defaultValue={0}
                            min={0}
                            inputMode="numeric"
                          />
                        </Form.Item>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "required",
                            },
                          ]}
                          labelAlign="left"
                          label="Rescheduling Needed"
                          name={[field.name, "reschedulingNeeded"]}
                        >
                          <InputNumber
                            defaultValue={0}
                            min={0}
                            inputMode="numeric"
                          />
                        </Form.Item>
                      </Card>
                    ))}
                  </div>
                )}
              </Form.List>
            </Form>
            <Form
              disabled={formDisabled}
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 22 }}
              form={snoozeForm}
              name="dynamic_form_complex"
              style={{ width: 600 }}
              autoComplete="off"
              initialValues={{ items: [{}] }}
            >
              <Form.List
                rules={[
                  {
                    validator(rule, value, callback) {
                      console.log(value);
                      if (value.length < 1) {
                        return Promise.reject(
                          "At least one snooze range is required"
                        );
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
                name="items"
              >
                {(fields, { add, remove, move }) => (
                  <div
                    style={{
                      display: "flex",
                      rowGap: 16,
                      flexDirection: "column",
                    }}
                  >
                    {fields.map((field) => (
                      <Card
                        className="admin-border"
                        size="small"
                        key={field.key}
                      >
                        <h4 style={{ margin: "10px 10px 0px 0" }}>Snooze</h4>
                        <div style={{ margin: "10px 0 12px 0" }}>
                          Please specify how long a customer should be snoozed
                          after not being reached for the Nth time.
                        </div>
                        <Form.Item>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <li>Not Reached Counter</li>
                            <li style={{ marginLeft: "165px" }}>
                              Snooze Duration
                            </li>
                          </div>
                          <Form.List name={[field.name, "snooze"]}>
                            {(subFields, subOpt) => (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  rowGap: 16,
                                }}
                              >
                                {subFields.map((subField, index) => (
                                  <Space key={subField.key}>
                                    {!formDisabled && (
                                      <UpOutlined
                                        style={{ margin: "0 0 0 5px" }}
                                        onClick={() =>
                                          subOpt.move(index, index - 1)
                                        }
                                        disabled={index === 0}
                                      />
                                    )}
                                    {!formDisabled && (
                                      <DownOutlined
                                        style={{ margin: "0px 0px" }}
                                        onClick={() =>
                                          subOpt.move(index, index + 1)
                                        }
                                        disabled={
                                          index === subFields.length - 1
                                        }
                                      />
                                    )}
                                    <Form.Item
                                      rules={[
                                        {
                                          required: true,
                                          message: "required",
                                        },
                                      ]}
                                      noStyle
                                      name={[subField.name, "start"]}
                                    >
                                      <InputNumber
                                        required={true}
                                        defaultValue={0}
                                        min={0}
                                        placeholder="Start"
                                      />
                                    </Form.Item>
                                    <div> - </div>
                                    <Form.Item
                                      rules={[
                                        {
                                          required: true,
                                          message: "required",
                                        },
                                      ]}
                                      noStyle
                                      name={[subField.name, "end"]}
                                    >
                                      <InputNumber
                                        required={true}
                                        defaultValue={0}
                                        min={0}
                                        placeholder="End"
                                      />
                                    </Form.Item>
                                    <ArrowRightOutlined
                                      style={{ margin: "0px 10px" }}
                                    />
                                    <Form.Item
                                      rules={[
                                        {
                                          required: true,
                                          message: "required",
                                        },
                                      ]}
                                      noStyle
                                      name={[subField.name, "duration"]}
                                    >
                                      <InputNumber
                                        required={true}
                                        defaultValue={0}
                                        min={0}
                                        placeholder="Duration"
                                      />
                                    </Form.Item>
                                    <Form.Item
                                      rules={[
                                        {
                                          required: true,
                                          message: "required",
                                        },
                                      ]}
                                      noStyle
                                      name={[subField.name, "timeFrame"]}
                                    >
                                      <Select
                                        style={{ width: 120 }}
                                        options={[
                                          { value: "m", label: "Minutes" },
                                          { value: "H", label: "Hours" },
                                          { value: "D", label: "Days" },
                                          { value: "W", label: "Weeks" },
                                          { value: "M", label: "Months" },
                                        ]}
                                        placeholder="Time frame"
                                      ></Select>
                                    </Form.Item>
                                    {!formDisabled && (
                                      <CloseOutlined
                                        disabled={formDisabled}
                                        style={{ margin: "0 5px 0 10px" }}
                                        onClick={() => {
                                          subOpt.remove(subField.name);
                                        }}
                                      />
                                    )}
                                  </Space>
                                ))}
                                <Button
                                  style={{ width: 550 }}
                                  type="dashed"
                                  onClick={() => subOpt.add()}
                                  block
                                >
                                  + Add Snooze Range
                                </Button>
                              </div>
                            )}
                          </Form.List>
                        </Form.Item>
                      </Card>
                    ))}
                  </div>
                )}
              </Form.List>
            </Form>
          </div>
        </div>
        {configurations && editingConfig && selectedConfiguration && (
          <div className="admin-container">
            <Tooltip title="Update selected configuration">
              <Button
                type="primary"
                onClick={saveClicked}
                loading={saveLoading}
                disabled={selectedConfiguration.default || disabled}
                style={{ marginRight: 10, float: "inline-end" }}
              >
                Save
              </Button>
            </Tooltip>
            <Tooltip title="Save as a new configuration">
              <Button
                type="default"
                disabled={configurations?.length >= 5 || disabled}
                onClick={saveAsClicked}
                loading={saveAsLoading}
                style={{ marginRight: 10, float: "inline-end" }}
              >
                Save As
              </Button>
            </Tooltip>
            <Tooltip title="Discard pending changes">
              <Button
                type="default"
                onClick={discardClicked}
                disabled={disabled}
                style={{
                  marginRight: 10,
                  float: "inline-end",
                }}
              >
                Cancel
              </Button>
            </Tooltip>
          </div>
        )}
      </div>
    </>
  );
};

export default AdminConfiguration;