import { SalesChannel } from "../dto/model";
import { BookingConfiguration, Configuration, ConfigurationPostBody } from "../dto/admin-config-models";

const rootUrl = (process as any).env.REACT_APP_API_ROOT_URL;

export async function getConfigurations(accessToken: string, salesChannel: SalesChannel): Promise<Configuration[] | null> {
  try {
    const response = await fetch(`${rootUrl}/api/v1/admin/configurations?salesChannel=${salesChannel}`, {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${accessToken}`,
      }),
    });
    if (response.ok) {
      const data = await response.json();
      return data; // Add this line to return the data
    } else {
      alert("Failed to get configuration");
    }
  } catch (error) {
    alert("Failed to get configuration");
  }
  return null;
}

export async function postConfiguration(accessToken: string, payload: ConfigurationPostBody, salesChannel: SalesChannel): Promise<boolean> {
  try {
    const response = await fetch(`${rootUrl}/api/v1/admin/configurations?salesChannel=${salesChannel}`, {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(payload),
    });
    return response.ok;
  } catch (error) {
    return false;
  }
}

export async function activateConfig(accessToken: string, configId: number, salesChannel: SalesChannel): Promise<boolean> {
  try {
    const response = await fetch(
      `${rootUrl}/api/v1/admin/configurations/${configId}/activate?salesChannel=${salesChannel}`,
      {
        method: "PATCH",
        headers: new Headers({
          Authorization: `Bearer ${accessToken}`,
        }),
      }
    );
    return response.ok;
  } catch (error) {
    return false;
  }
};

export async function updateConfig(accessToken: string, updateConfig: Configuration, salesChannel: SalesChannel): Promise<boolean> {
  try {
    console.log('updateConfig', updateConfig);
    const response = await fetch(
      `${rootUrl}/api/v1/admin/configurations/${updateConfig.id}?salesChannel=${salesChannel}`,
      {
        method: "PUT",
        headers: new Headers({
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify(updateConfig),
      });
    return response.ok;

  } catch (error) {
    return false;
  }
}
