import { strings } from "../lang";

const calculateHowLongCustomerHasBeenAdded = (creationDate: Date | null) => {
  const createdDate = creationDate ? new Date(creationDate) : null;
  if (createdDate) {
    const today = new Date();

    const timeDiff = Math.abs(today.getTime() - createdDate.getTime());
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

    if (daysDiff <= 1) {
      return strings.signedupPeriod.today;
    } else if (daysDiff <= 2) {
      return strings.signedupPeriod.yesterday;
    } else if (daysDiff <= 7) {
      return strings.signedupPeriod.daysAgo.replace(
        '{0}',
        daysDiff.toString()
      );
    } else if (daysDiff <= 30) {
      return strings.signedupPeriod.weeksAgo.replace(
        '{0}',
        Math.floor(daysDiff / 7).toString()
      );
    } else if (daysDiff <= 90) {
      return strings.signedupPeriod.monthsAgo.replace(
        '{0}',
        Math.floor(daysDiff / 30).toString()
      );
    } else {
      return strings.signedupPeriod.moreThan3MonthsAgo;
    }
  }
};

export { calculateHowLongCustomerHasBeenAdded };