import { Button, message, Modal, Tooltip } from "antd";
import { strings } from "../../lang";
import { OpportunityAssignDto, OpportunitySearchResult } from "../../dto/model";
import { CalendarOutlined, ClockCircleOutlined, EnvironmentOutlined, MailOutlined, PhoneOutlined, ShareAltOutlined, UserOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { GetAccessToken } from "../../utils/auth-utils";
import { useMsal } from "@azure/msal-react";
import { scopes } from "../../authConfig";
import { bookingPageService } from "../../services/booking-page-service";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { SalesCallCalendar } from "../appointment-booking/sales-call-calendar";
import { calculateHowLongCustomerHasBeenAdded } from "../../utils/inbound-utils";

type OpportunityResultProps = {
  opportunityResult: OpportunitySearchResult;
  isDisabled: boolean;
  setIsDisabled: (isDisabled: boolean) => void;
};

const sfUrl = (process as any).env.REACT_APP_SALESFORCE_BASE_URL;

const InboundOpportunity = ({ opportunityResult, isDisabled, setIsDisabled }: OpportunityResultProps) => {
  const { instance, inProgress } = useMsal();
  const navigate = useNavigate();
  const [isAssignLoading, setIsAssignLoading] = useState<boolean>(false);
  const [isCancelLoading, setIsCancelLoading] = useState<boolean>(false);
  const [isRescheduleLoading, setIsRescheduleLoading] = useState<boolean>(false);
  const [openReschedule, setOpenReschedule] = useState<boolean>(false);
  const [openCancel, setOpenCancel] = useState<boolean>(false);

  const onCancelButtonClick = async () => {
    setOpenCancel(true);
  }

  const onRescheduleButtonClick = async () => {
    setOpenReschedule(true);
  }

  const onShareButtonClick = async () => {
    navigator.clipboard
      .writeText(
        `${sfUrl}/lightning/r/Opportunity/${opportunityResult.opportunityId}/view`
      )
      .then(() => {
        message.success(strings.customerInfoCaseCopied);
      })
      .catch(() => {
        message.error(strings.customerInfoFailedToCopy);
      });
  }

  const onAssignToMeClicked = async () => {
    setIsAssignLoading(true);
    setIsDisabled(true);
    const body: OpportunityAssignDto = {
      opportunityId: opportunityResult.opportunityId,
      caseId: opportunityResult.caseId,
    };
    const token = await GetAccessToken(
      instance,
      inProgress,
      scopes.salesAppointmentApi
    );
    const response =
      await bookingPageService.assignInboundOpportunity(
        body,
        token.accessToken
      );

    if (response.isSuccess) {
      navigate('/booking', {
        state: {
          inboundCenter: true,
        },
      });
    } else {
      message.error(
        'Please go offline in the booking queue to assign yourself a new customer'
      );
    }
    setIsAssignLoading(false);
    setIsDisabled(false);
  }


  const onConfirmCancelation = async () => {
    setIsDisabled(true);
    setIsCancelLoading(true);
    const token = await GetAccessToken(
      instance,
      inProgress,
      scopes.salesAppointmentApi
    );
    const res = await bookingPageService.cancelAppointment(
      opportunityResult.externalAppointmentId!,
      opportunityResult.opportunityId!,
      token.accessToken,
      false
    );
    if (res.isSuccess) {
      opportunityResult!.appointmentStatus = 'Cancelled';
      opportunityResult!.bookingStatus = 'ReschedulingNeeded';
    } else {
      message.error(res.errors?.join(' '));
    }
    setIsDisabled(false);
    setIsCancelLoading(false);
    setOpenCancel(false);
  }

  return (
    <>
      <div
        key={opportunityResult.opportunityId}
        className="container-max-width inbound-container-border"
      >
        {opportunityResult.assignedAgent && (
          <div
            style={{
              padding: '20px 20px 0 20px',
              fontSize: 'small',
              color: '#EF4444',
            }}
          >
            {strings.attention(opportunityResult.assignedAgent)}
          </div>
        )}
        <div className="flex-inbound-container" style={{ padding: 20 }}>
          <div className="inbound-grid-container">
            <div
              className="bp-customer_icon_container bp-customer-title"
              style={{ gridArea: '1 / 1 / 1 / 3' }}
            >
              <UserOutlined />
              {opportunityResult.customerSalutation} {opportunityResult.customerFirstName}{' '}
              {opportunityResult.customerLastName}
              <div
                className="inbound-booking-state"
                style={{
                  backgroundColor: `${opportunityResult.bookingStatus === 'FirstTimeScheduling'
                    ? '#F0F0F0'
                    : opportunityResult.bookingStatus === 'ReschedulingNeeded'
                      ? '#F6DBDB'
                      : '#DBF6DE'
                    }`,
                }}
              >
                {opportunityResult.bookingStatus === 'FirstTimeScheduling'
                  ? 'First-Time Scheduling'
                  : opportunityResult.bookingStatus === 'ReschedulingNeeded'
                    ? 'Rescheduling Needed'
                    : opportunityResult.bookingStatus}
              </div>
              {opportunityResult.doNotCallBefore &&
                dayjs(opportunityResult.doNotCallBefore).toDate() > new Date() && (
                  <div
                    className="inbound-booking-state"
                    style={{
                      backgroundColor: '#F0F0F0',
                    }}
                  >
                    <Tooltip
                      title={dayjs(opportunityResult.doNotCallBefore)
                        .local()
                        .format('D MMM YYYY h:mm A')}
                    >
                      <ClockCircleOutlined /> Snoozed
                    </Tooltip>
                  </div>
                )}
            </div>
            <div
              className="bp-customer_icon_container bp-customer-title"
              style={{ gridArea: '2 / 2 / 2 / 2', width: 180 }}
            >
              <a href={`tel:${opportunityResult.phoneNumber}`}>
                <PhoneOutlined /> {opportunityResult.phoneNumber}
              </a>
            </div>
            <div
              className="bp-customer_icon_container"
              style={{ width: 310, gridArea: '2 / 1 / 2 / 1' }}
            >
              <CalendarOutlined />
              {calculateHowLongCustomerHasBeenAdded(opportunityResult.createdDate)}
            </div>
            <div
              className="bp-customer_icon_container"
              style={{ gridArea: '2 / 3 / 2 / 3' }}
            >
              <MailOutlined /> <span>{opportunityResult.email}</span>
            </div>
            <div
              className="bp-customer_icon_container"
              style={{ gridArea: '3 / 1 / 3 / 5' }}
            >
              <EnvironmentOutlined />
              {opportunityResult.fullAddress}
            </div>
            {opportunityResult.appointmentStartDate && (
              <div
                className="bp-customer_icon_container"
                style={{ gridArea: '4 / 1 / 4 / 1' }}
              >
                <h5 style={{ margin: 0, padding: '15px 0 5px 0' }}>
                  {strings.appointmentDetails}
                </h5>
              </div>
            )}
            {opportunityResult.appointmentStartDate && (
              <div style={{ gridArea: '5 / 1 / 5 / 2' }}>
                {opportunityResult.appointmentStartDate && (
                  <div
                    style={{
                      display: 'flex',
                      gap: '5px',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        margin: 0,
                        padding: 0,
                        fontWeight: 'bold',
                      }}
                    >
                      {dayjs(opportunityResult.appointmentStartDate)
                        .local()
                        .format('D MMM YYYY')}{' '}
                      at{' '}
                      {dayjs(opportunityResult.appointmentStartDate)
                        .local()
                        .format('h:mm A')}
                    </div>
                    <div
                      className="inbound-booking-state"
                      style={{
                        backgroundColor: '#F0F0F0',
                        color:
                          opportunityResult.appointmentStatus === 'Cancelled'
                            ? '#B60000'
                            : '#007A2D',
                      }}
                    >
                      {opportunityResult.appointmentStatus}
                    </div>
                  </div>
                )}

                {opportunityResult.appointmentStartDate && (
                  <div>
                    {strings.salesManager}: {opportunityResult.salesFirstName}{' '}
                    {opportunityResult.salesLastName}
                  </div>
                )}

                {opportunityResult.appointmentStartDate &&
                  opportunityResult.appointmentStatus === 'Reserved' && (
                    <div style={{ marginTop: '5px' }}>
                      <Button
                        loading={isCancelLoading}
                        disabled={isDisabled && !isCancelLoading}
                        style={{ marginRight: '10px' }}
                        danger
                        type="primary"
                        onClick={onCancelButtonClick}
                      >
                        {strings.cancelAppointment}
                      </Button>
                      <Button
                        loading={isRescheduleLoading}
                        disabled={isDisabled && !isRescheduleLoading}
                        onClick={onRescheduleButtonClick}
                      >
                        {strings.reschedule}
                      </Button>
                    </div>
                  )}
              </div>
            )}
            <div className="inbound-main-btns">
              <Button
                icon={<ShareAltOutlined />}
                onClick={onShareButtonClick}
              >
                {strings.share}
              </Button>
              <Tooltip
                title={
                  opportunityResult.bookingStatus.includes('OnHold') ||
                    opportunityResult.planningStatus.includes('OnHold')
                    ? 'Planning or SC1 is on hold'
                    : ''
                }
              >
                <Button
                  type="primary"
                  loading={isAssignLoading}
                  onClick={onAssignToMeClicked}
                  disabled={isDisabled && !isAssignLoading}
                >
                  {strings.assignToMeAndOpen}
                </Button>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={openReschedule}
        width={'fit-content'}
        height={'fit-content'}
        onCancel={() => {
          setOpenReschedule(false);
        }}
        footer={null}
      >
        <div className="bp-calendar" style={{ minWidth: 700 }}>
          <SalesCallCalendar
            globalCustomerId={opportunityResult?.globalCustomerId!}
            opportunityId={opportunityResult?.opportunityId!}
            referrerId={null}
            sourceCaseId={opportunityResult?.caseId!}
            displayHeader={false}
            isInbound={true}
            preBooking={async () => {
              setIsRescheduleLoading(true);
              const token = await GetAccessToken(
                instance,
                inProgress,
                scopes.salesAppointmentApi
              );
              const res = await bookingPageService.cancelAppointment(
                opportunityResult!.externalAppointmentId!,
                opportunityResult!.opportunityId,
                token.accessToken,
                true
              );

              if (res.isSuccess) {
                opportunityResult!.appointmentStatus = 'Cancelled';
                opportunityResult!.bookingStatus = 'ReschedulingNeeded';
              } else {
                message.error(res.errors?.join(' '));
              }
            }}
            onBooking={(isSuccess, bookedSlot, error) => {
              if (isSuccess) {
                opportunityResult!.externalAppointmentId =
                  bookedSlot?.externalId;
                opportunityResult!.appointmentStartDate =
                  bookedSlot?.startDate!;
                opportunityResult!.bookingStatus = 'Scheduled';
                opportunityResult!.salesFirstName =
                  bookedSlot?.salesManagerName!.split(' ')[0]!;
                opportunityResult!.appointmentStatus = 'Reserved';
                opportunityResult!.salesLastName = bookedSlot
                  ?.salesManagerName!.split(' ')
                  .slice(1)
                  .join(' ')!;
              } else {
                message.error(error);
              }
              setIsRescheduleLoading(false);
            }}
            outerModalClose={() => {
              setOpenReschedule(false);
            }}
            skipConfirmationAfterBooking={false}
          />
        </div>
      </Modal>
      <Modal
        open={openCancel}
        title={strings.cancelModal.confirmCancellation}
        onCancel={() => {
          if (!isCancelLoading) {
            setOpenCancel(false);
          }
        }}
        okText={strings.cancelModal.confirmCancellation}
        cancelText={strings.cancelModal.rescheduleInstead}
        footer={[
          <Button
            key="1"
            disabled={isDisabled}
            onClick={() => {
              setOpenCancel(false);
              setOpenReschedule(true);
            }}
          >
            {strings.cancelModal.rescheduleInstead}
          </Button>,
          <Button
            key="2"
            type="primary"
            loading={isCancelLoading}
            onClick={onConfirmCancelation}
          >
            {strings.cancelModal.confirmCancellation}
          </Button>,
        ]}
      >
        <li>{strings.cancelModal.text}</li>
      </Modal>
    </>

  )
}

export default InboundOpportunity;